import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import {
  AlertDialogComponent,
  Data as AlertDialogData,
  Response as AlertDialogResponse,
} from '@core/dialogs/alert-dialog/alert-dialog.component';

type Callback = () => unknown;

const DEFAULT_DIALOG_CONFIG: MatDialogConfig<unknown> = { maxWidth: '500px' };

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    public readonly dialog: MatDialog
  ) {}

  public info(message: string) {
    this.openSnackBar(message, '', 'info-snackbar');
  }

  public success(message: string) {
    this.openSnackBar(message, '', 'success-snackbar');
  }

  public warning(message: string) {
    this.openSnackBar(message, '', 'warning-snackbar');
  }

  public error(message: string) {
    this.openSnackBar(message, '', 'error-snackbar');
  }

  public confirmation(
    message: string,
    okCallback: Callback,
    title = 'Are you sure?',
    cancelCallback: Callback = () => {}
  ) {
    const dialogConfig: MatDialogConfig<AlertDialogData> = {
      ...DEFAULT_DIALOG_CONFIG,
      data: { message: message, title: title, cancelable: true },
    };

    const dialogRef = this.dialog.open<
      AlertDialogComponent,
      AlertDialogData,
      AlertDialogResponse
    >(AlertDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result && okCallback) {
        okCallback();
      }
      if (!result && cancelCallback) {
        cancelCallback();
      }
    });
  }

  public alert(
    message: string,
    title = 'Notice',
    okCallback: Callback = () => {}
  ) {
    const dialogConfig: MatDialogConfig<AlertDialogData> = {
      ...DEFAULT_DIALOG_CONFIG,
      data: { message: message, title: title, cancelable: false },
      disableClose: true,
    };

    const dialogRef = this.dialog.open<
      AlertDialogComponent,
      AlertDialogData,
      AlertDialogResponse
    >(AlertDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result && okCallback) {
        okCallback();
      }
    });
  }

  public openSnackBar(
    message: string,
    action: string,
    className = '',
    duration = 1000
  ) {
    return this.snackBar.open(message, action, {
      duration: duration,
      panelClass: [className],
    });
  }
}
