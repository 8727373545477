import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { AnyProject, ScaleProject } from '@core/models/project.model';
import { isSetup } from '@core/utils/project';
import { ComponentStore } from '@ngrx/component-store';
import { iif, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

interface State {
  selected: AnyProject | null;
}

@Injectable({ providedIn: 'root' })
export class ProjectSetupStore extends ComponentStore<State> {
  public readonly selected$: Observable<AnyProject | null> = this.select(
    (state) => state.selected
  );
  public readonly selectedIsSetup$: Observable<boolean | null> =
    this.selected$.pipe(map((p) => p && isSetup(p)));
  public readonly selectedAsScaleProject$: Observable<ScaleProject | null> =
    this.selectedIsSetup$.pipe(
      switchMap((isSetup) =>
        this.selected$.pipe(
          map((project) =>
            isSetup && project ? (project as ScaleProject) : null
          )
        )
      )
    );

  constructor(
    private readonly notification: NotificationService,
    private readonly router: Router
  ) {
    super({ selected: null });
  }

  public readonly selectProject = this.effect<AnyProject>((project$) =>
    project$.pipe(
      switchMap((project) =>
        iif(
          () => isSetup(project),
          of(project).pipe(
            tap(() => {
              this.router.navigate(['project', project.ProjectNumber]);
              this.notification.warning('Going to edit page');
              this.setProject(project);
            })
          ),
          of(project).pipe(tap(this.setProject))
        )
      )
    )
  );

  public readonly setProject = this.updater<AnyProject | null>(
    (state, project) => {
      return {
        ...state,
        selected: project,
      };
    }
  );
}
