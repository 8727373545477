<h1 mat-dialog-title>{{ data?.title }}</h1>

<div mat-dialog-content>
  {{ data?.message }}
</div>

<div mat-dialog-actions align="end">
  <button
    *ngIf="data?.cancelable"
    mat-raised-button
    color="warn"
    (click)="onNoClick()"
  >
    Cancel
  </button>

  <button
    mat-raised-button
    color="primary"
    (click)="onYesClick()"
    cdkFocusInitial
  >
    Ok
  </button>
</div>
